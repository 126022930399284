import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BurgerStyle = styled.div<{
  $open: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  span {
    display: block;
    height: 2px;
    background: #ddd;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 0;

    :first-child {
      transform: ${(props) => (props.$open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${(props) => (props.$open ? '0' : '1')};
    }

    :nth-child(3) {
      transform: ${(props) => (props.$open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const Burger: React.FC<{
  className?: string
  onClick?: () => void
  open: boolean
}> = ({ className, onClick, open }) => {
  return (
    <BurgerStyle className={className} $open={open} onClick={onClick}>
      <span />
      <span />
      <span />
    </BurgerStyle>
  )
}

Burger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Burger
