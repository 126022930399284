import React, { FormEventHandler, useCallback, useState } from 'react'
import { Row, Col } from 'ui/elements/Grid'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Link, { useNavigation } from 'widgets/Link'
import Button from 'ui/elements/Button'
import { Heading } from 'ui/elements/Heading/styles'
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'
import TextInput from 'ui/elements/TextInput'

const SNewsletter = styled.div``

const SHeader = styled.header`
  padding-right: 30%;
  margin-bottom: 20px;

  ${Heading} {
    margin-bottom: 15px;

    &:last-child {
      margin: 0;
    }
  }
`

const SFooter = styled.footer`
  color: rgb(161, 161, 161);
  font-size: 1.2rem;

  a {
    color: ${(props) => props.theme.palette.white};
  }

  p {
    margin-bottom: 0;
  }
`

const SError = styled.p``

const Newsletter: React.FC = () => {
  const { t } = useTranslation('footer')
  const [error, setError] = useState<'email' | 'server'>()
  const navigate = useNavigation()

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault()

      setError(undefined)

      const data = new FormData(event.currentTarget)
      const post = new URLSearchParams()

      const email = data.get('email')
      if (typeof email !== 'string') {
        setError('email')
        return
      }

      const regex =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
      if (!regex.test(email)) {
        setError('email')
        return
      }

      post.append('email', email)

      try {
        await fetch('https://go.pardot.com/l/877492/2023-10-09/5w11gf', {
          method: 'POST',
          mode: 'no-cors',
          body: post,
        })

        await navigate('/thank-you')
      } catch (e) {
        setError('server')
      }
    },
    [navigate]
  )

  return (
    <SNewsletter>
      <SHeader>
        <OpticalHeading level={3}>{t('newsletter.title')}</OpticalHeading>
        <OpticalHeading level={6}>{t('newsletter.subtitle')}</OpticalHeading>
      </SHeader>
      {error === 'email' && <SError>{t('newsletter.error.email')}</SError>}
      {error === 'server' && <SError>{t('newsletter.error.server')}</SError>}
      <form onSubmit={onSubmit}>
        <Row $gutterWidth={10}>
          <Col $xs={12} $md={8}>
            <TextInput
              fill
              placeholder={t('newsletter.email')}
              color="shade"
              name="email"
              type="email"
              required
            />
          </Col>
          <Col $xs={12} $md={4}>
            <Button
              as="button"
              type="submit"
              color="shade"
              label={t('newsletter.button')}
              fill
            />
          </Col>
        </Row>
      </form>
      <SFooter>
        <Link to="/privacy-statement">{t('newsletter.disclaimer')}</Link>
      </SFooter>
    </SNewsletter>
  )
}

export default Newsletter
