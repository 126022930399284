import React, { useState, useRef, useCallback } from 'react'

import { Container, Row, Col } from 'ui/elements/Grid'

import { useMobileNav } from '../Navigation/utils'
import Icon from '../Icon'
import Button from '../Button'
import * as S from './styles'
import { lazyload } from 'utils/lazyload'

const SearchAnimated = lazyload(() => import('./SearchAnimated'))

const SearchFieldContainer = React.forwardRef(
  ({ active, onSuccess, top, isMobileNav }, ref) => {
    const [query, setQuery] = useState('')

    const Component = (
      <Container>
        <Row>
          <Col $xs={12}>
            <S.SearchForm
              onSubmit={(ev) => {
                ev.preventDefault()

                onSuccess(query)
              }}
            >
              <Icon name="search" />
              <S.InputContainer>
                <input
                  ref={ref}
                  type="search"
                  placeholder="Search for a program, conference or news article"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
              </S.InputContainer>
              <Button
                as="button"
                type="submit"
                iconName="arrow-right"
                label="Search"
                variant="clean"
              />
            </S.SearchForm>
          </Col>
        </Row>
      </Container>
    )

    if (isMobileNav) {
      return (
        <S.MobileSearchFieldContainer>{Component}</S.MobileSearchFieldContainer>
      )
    }

    return (
      <SearchAnimated active={active} top={top}>
        {Component}
      </SearchAnimated>
    )
  }
)

const Search = ({ onSuccess, searchStart }) => {
  const isMobileNav = useMobileNav()
  const [active, _setActive] = useState(false)
  const inputRef = useRef(null)

  const setActive = useCallback((active) => {
    _setActive(active)

    if (!active) {
      return
    }

    inputRef.current?.focus()
  }, [])

  return (
    <>
      <S.SearchTrigger $active={active} onClick={() => setActive(!active)}>
        <Icon name="search" size={18} color="black" />
      </S.SearchTrigger>
      <SearchFieldContainer
        onSuccess={(q) => {
          setActive(false)
          onSuccess(q)
        }}
        ref={inputRef}
        active={active}
        top={!isMobileNav && `${searchStart ?? 94}px`}
        isMobileNav={isMobileNav}
      />
    </>
  )
}

export default Search
