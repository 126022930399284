import React, { createContext, useContext, useMemo } from 'react'

export const useGetLinkTranslations = (
  translations: Record<string, string> | undefined,
  languages: string[],
  path: string,
  defaultLanguage: string
) => {
  const trans = useMemo(() => {
    if (translations) {
      return translations
    }

    const r = new RegExp(`^/?(${languages.join('|')})/`)
    const originalPath = path.replace(r, '/')

    const regexp = /(?<!https:)\/\/+/g
    return Object.fromEntries(
      languages.map((l) => {
        const fullPath = `/${
          l === defaultLanguage ? '' : l
        }/${originalPath}/`.replaceAll(regexp, '/')
        return [l, fullPath]
      })
    )
  }, [path])

  return {
    translations: trans,
  }
}

const useCreateContext = useGetLinkTranslations

export const LanguageSwitcherContext = createContext<ReturnType<
  typeof useCreateContext
> | null>(null)

export const useLanguageSwitcherContext = () => {
  const ctx = useContext(LanguageSwitcherContext)

  if (ctx === null) {
    throw new Error('No LanguageSwitcherContextProvider available.')
  }

  return ctx
}

export const LanguageSwitcherContextProvider: React.FC<
  React.PropsWithChildren<{
    translations: Record<string, string>
    languages: string[]
    path: string
    defaultLanguage: string
  }>
> = ({ children, translations, languages, path, defaultLanguage }) => {
  const ctx = useCreateContext(translations, languages, path, defaultLanguage)

  return (
    <LanguageSwitcherContext.Provider value={ctx}>
      {children}
    </LanguageSwitcherContext.Provider>
  )
}
