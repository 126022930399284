import urlBuilder from '@sanity/image-url'
import _map from 'lodash/map'
import _get from 'lodash/get'
import type {
  SanityImageSource,
  SanityProjectDetails,
} from '@sanity/image-url/lib/types/types'

export const config: SanityProjectDetails = {
  projectId: process.env.SANITY_PROJECT_ID as string,
  dataset: process.env.SANITY_DATASET as string,
}

export const getImage = (id: SanityImageSource) => urlBuilder(config).image(id)

const mapBlock = (block: any) => {
  if (Array.isArray(block)) {
    return block.map(mapBlock).join('')
  }

  if (block._type !== 'block' || !block.children) {
    return ''
  }

  return block.children.map((child: any) => child?.text).join('')
}

export const toPlainText = (blocks: Queries.SanityBlock[] = []): string => {
  return _map(blocks, mapBlock).join('\n\n').trim()
}

export const getImageURL = (
  data: any,
  path = 'hero.asset._id',
  w = 800,
  h = 600
) => {
  const imageId = _get(data, path)
  if (!imageId) {
    return undefined
  }

  return getImage(imageId).width(w).height(h).url()
}
