import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

import * as S from './styles'
import { useLanguageSwitcherContext } from 'contexts/LanguageSwitcherContext'

const LanguageSwitcher = () => {
  const { languages, language, originalPath } = useI18next()

  const { translations } = useLanguageSwitcherContext()

  return (
    <S.LanguageSwitcher>
      {languages.map((lang) => {
        return (
          <S.Language
            key={lang}
            $active={language === lang}
            lang={lang}
            language={lang}
            to={translations[lang]}
          >
            <S.Label>{lang}</S.Label>
          </S.Language>
        )
      })}
    </S.LanguageSwitcher>
  )
}

export default LanguageSwitcher
