import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _sortBy from 'lodash/sortBy'

import type { MenuEntryType, MenuSubEntry } from 'ui/elements/Menu/Menu'
import NewMenu from 'ui/elements/Menu/Menu'

import { palette as openProgramsPalette } from 'modules/OpenPrograms/utils'
import { palette as customizedProgramsPalette } from 'modules/CustomizedPrograms/utils'

import { useNavigation } from 'widgets/Link'

import LanguageSwitcher from './LanguageSwitcher'
import { graphql, useStaticQuery } from 'gatsby'
import { slugify } from 'utils/slugify'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMenuContext } from 'ui/elements/Menu/MenuContext'
import Search from 'ui/elements/Search'

const ReadOpenProgramsSubnav = graphql`
  query OpenProgramsNavigation {
    allSanityCategories(sort: [{ sort: ASC }, { title: ASC }]) {
      edges {
        node {
          id
          title
          sort
        }
      }
    }
    allSanityProgram(
      filter: { disabled: { ne: true }, hidden: { ne: true } }
      sort: [{ title: ASC }]
    ) {
      edges {
        node {
          id
          title
          category {
            id
          }
        }
      }
    }
  }
`

export const useOpenProgramsCategories = () => {
  const { allSanityCategories: categories, allSanityProgram: programs } =
    useStaticQuery<Queries.OpenProgramsNavigationQuery>(ReadOpenProgramsSubnav)

  return useMemo<MenuSubEntry[]>(() => {
    return _sortBy(categories.edges, ['sort', 'title']).map(
      ({ node: category }): MenuSubEntry => {
        return {
          id: `category-${category.id}`,
          title: category.title ?? '',
          items: _sortBy(
            programs.edges.filter(
              ({ node: program }) => program.category?.id === category.id
            ),
            ['title']
          ).map(({ node: program }) => {
            return {
              id: `program-${program.id}`,
              title: program.title ?? '',
              // TODO: this should really happen on the gatsby-node side of things...
              href: `/open-programs/${slugify(program.title, 'en')}/`,
            }
          }),
        }
      }
    )
  }, [categories, programs])
}

const Navigation: React.FC<{
  hasNavigation?: boolean
  searchStart?: number
  onMenuOpen?: (state: boolean) => void
}> = ({ hasNavigation, searchStart, onMenuOpen }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['navigation', 'common'])
  const openProgramsCategories = useOpenProgramsCategories()
  const navigate = useNavigation()

  const { menuOpen } = useMenuContext()
  useEffect(() => {
    if (!onMenuOpen) {
      return
    }

    onMenuOpen(menuOpen)
  }, [menuOpen, onMenuOpen])

  const onSearch = useCallback(
    (q: string) => navigate(`/search/?q=${q}`),
    [navigate]
  )

  const entries = useMemo<MenuEntryType[]>(() => {
    if (!hasNavigation) {
      return [
        {
          id: 'language',
          item: <LanguageSwitcher />,
        },
      ]
    }

    return [
      {
        id: 'programs',
        title: 'Programs',
        submenus: [
          {
            id: 'open-programs',
            title: 'Open Programs',
            subtitle: t('navigation:personalDevelopment'),
            icon: 'open-programs',
            palette: openProgramsPalette,
            cta: {
              label: t('navigation:openProgramsOverview'),
              href: '/open-programs/',
            },
            items: openProgramsCategories,
          },
          {
            id: 'customized-programs',
            title: 'Customized Programs',
            subtitle: t('navigation:companySpecificGoals'),
            icon: 'customized-programs',
            palette: customizedProgramsPalette,
            cta: {
              label: t('common:findOutMore'),
              href: {
                type: 'external',
                href:
                  language === 'de'
                    ? 'https://www.whu.edu/de/programme/executive-education/customized-programs/'
                    : 'https://www.whu.edu/en/programs/executive-education/customized-programs/',
              },
            },
          },
        ],
      },
      {
        id: 'conferences',
        title: 'Conferences',
        href: '/conferences/',
      },
      {
        id: 'experience',
        title: 'The Experience',
        href: '/the-experience/',
      },
      {
        id: 'news',
        title: 'News',
        href: '/news/',
      },
      {
        id: 'about',
        title: 'About',
        href: '/about-whu/',
      },
      {
        id: 'search',
        item: <Search searchStart={searchStart} onSuccess={onSearch} />,
      },
      {
        id: 'language',
        item: <LanguageSwitcher />,
      },
    ]
  }, [onSearch, t, language])

  return <NewMenu entries={entries} />
}

Navigation.propTypes = {
  hasNavigation: PropTypes.bool,
}

Navigation.defaultProps = {
  hasNavigation: true,
}

export default Navigation
